import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_ctx.authorized && _ctx.loadedData)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: 'SyncPanel-' + _ctx.source,
        class: "card"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("strong", null, "Last sync " + _toDisplayString(_ctx.source) + ":", 1),
          (_ctx.lastSync)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.lastSyncMsg), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_b_button, {
            variant: "link",
            disabled: _ctx.syncing,
            size: "sm",
            onClick: _ctx.sync
          }, {
            default: _withCtx(() => [
              (!_ctx.syncing)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, { icon: "spinner" }),
                    _createTextVNode(" Sync " + _toDisplayString(_ctx.source), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.syncing)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createVNode(_component_b_spinner, {
                      small: "",
                      type: "grow"
                    }),
                    _createTextVNode(" Loading ")
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}