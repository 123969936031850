
import {Vue} from "vue-class-component";
import {useSessionStore, useSyncStore} from "@/store";
import {StatsService} from "@/services/stats-service";
import {ITopTag, ITopTags} from "@/models/stats-models";

export default class TopTags extends Vue {
  limitTags: number = 200

  authorized: boolean = false
  loadedData: boolean = false

  sessionStore = useSessionStore()
  statsService = new StatsService()
  syncStore = useSyncStore()

  topTags: ITopTag[] = [] as ITopTag[]

  isAuthorized() {
    this.authorized = this.sessionStore.isAuthorized;
  }

  mounted() {
    this.loadedData = false;

    this.isAuthorized();

    this.sessionStore.$subscribe((mutation, state) => {
      this.authorized = state.authorized;
    });

    this.syncStore.$subscribe(() => {
      console.debug(`Got sync store change - reloading component data`)
      this.loadTopTags()
    })
  }

  loadTopTags() {
    let self = this

    this.statsService.getTopTags(this.limitTags).then((responseData: ITopTags) => {
      self.topTags = responseData.tags
      self.loadedData = true
    });
  }
}


